import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
const apiCoreBaseUrl = environment.apiCoreBase
const apiBaseUrl = environment.apiBase
const apisreBase = environment.apisreBase
@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private authService: AuthService) { }

  options = {
    headers: new HttpHeaders({
      Authorization: this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
    })
  };

  apiTesst(): Observable<any> {
    return this.http
      .get<any>(`https://printer.sunshinegroup.vn:8080/api/printer`);
  }

  getKhachHang(queryParams, type): Observable<any> {
    if (type == 0) {
      return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustIndiPage?` + queryParams, this.options);
    } else {
      return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coporate/GetCustCorpPage?` + queryParams, this.options);
    }
  }

  getListMenuByUserId(userId, webId): Observable<any> {
    return this.http
      .get<any>(`${apiBaseUrl}/api/v1/user/ClientMenuGetListByUserId?` +
        `userId=${userId}&webId=${webId}`, this.options);
  }

  getUserTypes(): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetUserTypes`, this.options)
  }

  getUserSearchPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetUserSearchPage?` + queryParams, this.options)
  }

  removeCustProfile(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/customer/RemoveCustProfile?` + queryParams, this.options)
  }

  getManagerList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetManagerList?` + queryParams, this.options);
  }

  setWorkSubmit(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/customer/SetWorkSubmit`, params, this.options);
  }

  getCustObjectListNew(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustObjectList?` + queryParams, this.options);
  }

  getBankList(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/GetBankList`, this.options)
  }

  getAccountPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/GetAccountPage?` + queryParams, this.options)
  }

  getAgencyOrganizeList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/getAgencyOrganizeList?` + queryParams, this.options);
  }

  getAgentLeaders(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/getAgentLeaders?` + queryParams, this.options);
  }

  getProductProjs(): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetProductProjs`, this.options)
  }

  setCustIndiIdentityCreate(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiIdentityCreate`, params, this.options);
  }

  getCustIndiCreate(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustIndiCreate`, params, this.options);
  }

  setCustIndiCreate(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiCreate`, params, this.options);
  }

  getCustIndiFromId(data): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustIndiFromId`, data, {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
      }),
      // responseType: "blob"
    });
  }

  getCustIndiFields(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustIndiFields?` + queryParams, this.options)
  }

  setCustIndiIdentity(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiIdentity`, params, this.options);
  }

  geAddressList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/corelogin/GeAddressList?` + queryParams, this.options)
  }

  getSubProductList(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreservice/GetSubProductList?agent_flg=1`, this.options)
  }

  setCustAddressContact(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustAddressContact`, params, this.options);
  }

  getIdentityCardInfomation(image: File) {
    const options = {
      headers: new HttpHeaders({
        'key': 'WZWfWCN2VPDxbYsV6sRfR0N1fV8x030h'
      })
    };
    const formdata = new FormData();
    formdata.append('image', image, 'TanTano');
    formdata.append('request_id', '14071996');
    return this.http.post('https://api.cloudekyc.com/v3.2/ocr/recognition', formdata, options);
  }


  setCustIndiMerge(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiMerge`, params, this.options);
  }

  searchCustomer(queryParams): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustIndividualPage?` + queryParams, this.options);
  }

  getCustCoporatePage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustCoporatePage?` + queryParams, this.options)
  }

  lockUser(data): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreuser/LockUser`, data, this.options)
  }

  unLockUser(data): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreuser/UnLockUser`, data, this.options)
  }

  deleteCustUser(params): Observable<any> {
    return this.http
      .delete<any>(`${apiCoreBaseUrl}/api/v1/individual/DeleteCustUser?` + params, this.options);
  }

  setCustIndiIdentityDefault(params) {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiIdentityDefault`, params, this.options)
  }

  setCustCorpFields(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coporate/SetCustCorpFields`, params, this.options);
  }

  delCustAddressContact(queryParams) {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/individual/DelCustAddressContact?` + queryParams, this.options)
  }

  delCustIndiIdentity(queryParams) {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/individual/DelCustIndiIdentity?` + queryParams, this.options)
  }

  setAccountStatus(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/SetAccountStatus`, params, this.options)
  }

  delAccount(params): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/DelAccount?` + params, this.options)
  }

  getCustProfileUserPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustProfileUserPage?` + queryParams, this.options)
  }

  getCustMetas(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustMetas?` + queryParams, this.options)
  }

  delCustMeta(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/individual/DelCustMeta?` + queryParams, this.options)
  }

  setCustIndiFields(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustIndiFields`, params, this.options);
  }

  getCustIndiIdentity(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/individual/GetCustIndiIdentity?` + queryParams, this.options)
  }

  setCustUser(params): Observable<any> {
    return this.http
      .post<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustUser`, params, this.options);
  }

  setCustProfileVerified(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/individual/SetCustProfileVerified`, params, this.options)
  }

  getAccountDetail(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/GetAccountDetail?` + queryParams, this.options)
  }

  setProfileInfo(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreuser/SetProfileInfo`, params, this.options)
  }

  setAccountInfo(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/SetAccountInfo`, params, this.options)
  }

  getAccountInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/GetAccountInfo?` + queryParams, this.options)
  }

  getProfileInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetProfileInfo?` + queryParams, this.options)
  }

  delProfileMeta(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coreuser/DelProfileMeta?` + queryParams, this.options)
  }

    //CoreCustCorporate

  getCustCorpFields(queryParams) {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coporate/GetCustCorpFields`, queryParams, this.options);
  }

  getCustCorpPage(queryParams) {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coporate/GetCustCorpPage`, queryParams, this.options);
  }

  getCorpCAPage(queryParams) {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coporate/GetCorpCAPage?` +  queryParams, this.options);
  }

  getCustCorpRep(queryParams) {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coporate/GetCustCorpRep` ,  queryParams, this.options);
  }

  getCustCorpRepPage(queryParams) {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coporate/GetCustCorpRepPage?` +  queryParams, this.options);
  }

  getCorpCAInfo(queryParams) {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coporate/GetCorpCAInfo?` +  queryParams, this.options);
  }

  delCorpCAReg(queryParams) {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coporate/DelCorpCAReg?` +  queryParams, this.options);
  }

  setCorpCAReg(queryParams) {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coporate/SetCorpCAReg` ,  queryParams, this.options);
  }

  setCustCorpRep(queryParams) {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coporate/SetCustCorpRep` ,  queryParams, this.options);
  }

  setPosOrganizeService(queryParams) {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coporate/SetPosOrganizeService`, queryParams, this.options);
  }

  setPosOrganizeMeta(queryParams) {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coporate/SetPosOrganizeMeta`, queryParams, this.options);
  }

  getPosOrganizeService(queryParams) {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetPosOrganizeService?` + queryParams, this.options);
  }

  getPosOrganizeMeta(queryParams) {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/getPosOrganizeMeta?` + queryParams, this.options);
  }

  searchCoporatePage(queryParams): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustCoporatePage?` + queryParams, this.options);
  }

  setCustCopoFields(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coporate/SetCustCopoFields`, params, this.options);
  }

  setProfileCreate(queryParams): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreuser/SetProfileCreate`, queryParams, this.options)
  }

  setWorkApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/customer/SetWorkApprove`, queryParams, this.options)
  }

  deleteUser(queryParams): Observable<any> {
    return this.http
      .delete<any>(`${apiCoreBaseUrl}/api/v1/coreuser/DeleteUser?` + queryParams, this.options)
  }

  delCustCorpRep(queryParams): Observable<any> {
    return this.http
      .delete<any>(`${apiCoreBaseUrl}/api/v1/coporate/DelCustCorpRep?` + queryParams, this.options)
  }

  resetPasswordOtp(queryParams): Observable<any> {
    return this.http
      .put<any>(`${apiCoreBaseUrl}/api/v1/coreuser/ResetPasswordOtp`, queryParams, this.options)
  }

  setProfileIdcardVerify(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreuser/SetProfileIdcardVerify`, params, this.options);
  }

  getProfilePage(queryParams): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetProfilePage?` + queryParams, this.options)
  }

  getProductPage(): Observable<any> {
    return this.http
      .get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetProductPage`, this.options)
  }

  getPosOrganizePage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/getPosOrganizePage?` + queryParams, this.options)
  }

  getWorkflowPage(params): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetWorkflowPage?` + params, this.options);
  }

  delPosOrganizeInfo(queryParams) {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coreagent/DelPosOrganizeInfo?` + queryParams, this.options)
  }

  getSalerPositions(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerPositions?` + queryParams, this.options)
  }

  setAgentClose(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coreagent/SetAgentClose?` + queryParams, this.options)
  }

  delSalerInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coreagent/DelSalerInfo?` + queryParams, this.options)
  }

  getContract(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresaler/GetContract?` + queryParams, this.options)
  }

  setAgentApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreagent/SetAgentApprove`, queryParams, this.options)
  }

  delPosOrganizeMeta(queryParams) {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coreagent/DelPosOrganizeMeta?` + queryParams, this.options)
  }

  delPosOrganizeService(queryParams) {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coreagent/DelPosOrganizeService?` + queryParams, this.options)
  }

  getRegionList(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetRegionList`, this.options);
  }

  getPosOrganizeInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetPosOrganizeInfo?` + queryParams, this.options);
  }

  setAgencyOrganize(queryParams): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreagent/SetAgencyOrganize`, queryParams, this.options)
  }

  getCustProfileFields(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustProfileFields`, params, this.options);
  }

  setCustProfileFields(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/customer/SetCustProfileFields`, params, this.options);
  }

  getSalerInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerInfo?` + queryParams, this.options);
  }

  getRoomPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apisreBase}/api/v2/ksfinance/GetRoomPage?` + queryParams, this.options);
  }

  getRoomBySalerId(queryParams): Observable<any> {
    return this.http.get<any>(`${apisreBase}/api/v2/ksfinance/GetRoomBySalerId?` + queryParams, this.options);
  }

  delRoomSaler(queryParams): Observable<any> {
    return this.http.put<any>(`${apisreBase}/api/v2/ksfinance/DelRoomSaler`, queryParams , this.options);
  }

  getProjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apisreBase}/api/v1/shousing/GetProjectList?` + queryParams, this.options);
  }

  getBuildingList(queryParams): Observable<any> {
    return this.http.get<any>(`${apisreBase}/api/v1/shousing/GetBuildingList?` + queryParams, this.options);
  }

  addRoomSaler(params): Observable<any> {
    return this.http.put<any>(`${apisreBase}/api/v2/ksfinance/AddRoomSaler`, params, this.options);
  }

  setCapaApprove(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreagent/SetCapaApprove`, params, this.options);
  }

  setPosOrganizeInfo(data) {
    return this.http
      .put<any>(`${apiCoreBaseUrl}/api/v1/coreagent/SetPosOrganizeInfo`, data, this.options)
  }

  setSalerInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreagent/SetSalerInfo`, queryParams, this.options)
  }

  getSaler(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSaler?`+ queryParams, this.options)
  }

  setSaler(queryParams): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreagent/SetSaler`, queryParams, this.options)
  }


  getAgencyOrganizeMap(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetAgencyOrganizeMap`, this.options);
  }

  getAgencyOrganizeTypes(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagency/GetAgencyOrganizeTypes`, this.options);
  }

  delAgencyOrganize(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coreagent/DelAgencyOrganize?` + queryParams, this.options)
  }

  getSalerOrganizeMap(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerOrganizeMap`, this.options);
  }

  getSalerPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerPage?` + queryParams, this.options);
  }

  getSalerTypes(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerTypes`, this.options);
  }

  setSalerAssign(params) {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreagent/SetSalerAssign`, params, this.options)
  }

  getGridViewPage(url, queryParams): Observable<any> {
    return this.http.get(`${apiCoreBaseUrl}/api/v1/config/${url}?` + queryParams, this.options);
  }

  setGridViewInfo(url, queryParams): Observable<any> {
    return this.http.post(`${apiCoreBaseUrl}/api/v1/config/${url}`, queryParams, this.options);
  }

  delFormViewInfo(url, queryParams): Observable<any> {
    return this.http.delete(`${apiCoreBaseUrl}/api/v1/config/${url}?` + queryParams, this.options);
  }

  delGridViewInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiCoreBaseUrl}/api/v2/config/DelGridViewInfo?` + queryParams, this.options);
  }

  getFormViewPage(queryParams): Observable<any> {
    return this.http.get(`${apiCoreBaseUrl}/api/v1/config/GetFormViewPage?` + queryParams, this.options);
  }

  setFormViewInfo(queryParams): Observable<any> {
    return this.http.post(`${apiCoreBaseUrl}/api/v1/config/SetFormViewInfo`, queryParams, this.options);
  }

  xuatBaoCaoHSNL(queryParams): Observable<any> {
    return this.http.get(`${apiCoreBaseUrl}/api/v1/coreagent/GetSalerCapaReport?${queryParams}`, {...this.options, responseType: 'blob'});
  }
  changeLoginNameForSaler(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coresaler/ChangeLoginNameForSaler`, params, this.options);
  }

}

